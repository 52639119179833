import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { BREAKPOINTS, mediaQueries } from "../styles";

const GiveawaySectionWrapper = styled.div`
  background-color: #9d58a6;
  color: #fff;
  display: flex;
  justify-content: center;
`;

const GiveawaySectionContent = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: center;

  ${mediaQueries.SM} {
    width: ${BREAKPOINTS.SM};
  }
`;

const MessageTitle = styled.h1`
  font-family: Rockwell, Courier Bold, Courier, Georgia, Times, Times New Roman,
    serif;
  font-size: 24px;
  margin-bottom: 10px;
`;

export const GiveawaySection: FunctionComponent = () => {
  return (
    <GiveawaySectionWrapper>
      <GiveawaySectionContent>
        <MessageTitle>Giveaways Coming Soon!</MessageTitle>
        <p>
          We have 100 monsters to give away. We are going to start very soon!
        </p>
      </GiveawaySectionContent>
    </GiveawaySectionWrapper>
  );
};
