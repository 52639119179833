import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { BREAKPOINTS, mediaQueries } from "../styles";

const UtilitySectionWrapper = styled.div`
  background-color: #9d58a6;
  color: #fff;
  display: flex;
  justify-content: center;
  text-shadow: 1px 1px #834c8c;

  ${mediaQueries.SM} {
    font-size: 32px;
    text-shadow: 3px 3px #834c8c;
  }
`;

const UtilitySectionContent = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  text-align: center;

  ${mediaQueries.SM} {
    width: ${BREAKPOINTS.SM};
  }
`;

const MessageTitle = styled.h1`
  font-family: Rockwell, Courier Bold, Courier, Georgia, Times, Times New Roman,
    serif;
  font-size: 40px;
  margin-bottom: 10px;
`;

const MessageDescription = styled.div`
  margin-bottom: 20px;
`;

// const Image = styled.img`
//   border: 3px solid #fff;
//   border-radius: 50px;
//   height: auto;
//   max-width: 600px;
//   width: 100%;
// `;

const Paragraph = styled.p`
  margin-bottom: 20px;

  ${mediaQueries.SM} {
    margin-bottom: 50px;
  }
`;

const Link = styled.a`
  text-shadow: none;
`;

export const UtilitySection: FunctionComponent = () => {
  return (
    <UtilitySectionWrapper>
      <UtilitySectionContent>
        <MessageTitle>Hi there</MessageTitle>
        <MessageDescription>
          <Paragraph>
            My name is Chris and I'm the creator of Imagination Monsters. I love
            creating things, and have been drawing monsters like these for my
            kids over the years.
          </Paragraph>

          <Paragraph>
            Consider buying a monster. They are very low maintenance.
          </Paragraph>

          <Paragraph>
            <Link
              href="https://twitter.com/chris_campb3ll"
              target="_blank"
              rel="noreferrer"
            >
              Follow me on twitter
            </Link>
            -
            <Link
              href="https://www.linkedin.com/in/centinel3/"
              target="_blank"
              rel="noreferrer"
            >
              Follow me on linkedin
            </Link>
          </Paragraph>
        </MessageDescription>
      </UtilitySectionContent>
    </UtilitySectionWrapper>
  );
};
