import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { BREAKPOINTS, mediaQueries } from "../styles";

const TokenSectionWrapper = styled.div`
  background-color: #eee;
  display: flex;
  justify-content: center;
`;

const TokenSectionContent = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  text-align: center;

  ${mediaQueries.SM} {
    width: ${BREAKPOINTS.SM};
  }
`;

const MessageTitle = styled.h1`
  font-family: Rockwell, Courier Bold, Courier, Georgia, Times, Times New Roman,
    serif;
  font-size: 40px;
  margin-bottom: 10px;
`;

const MessageDescription = styled.div``;

export const TokenSection: FunctionComponent = () => {
  return (
    <TokenSectionWrapper>
      <TokenSectionContent>
        <MessageTitle>$Monster Token</MessageTitle>
        <MessageDescription>
          <p>
            We are working on launching a $monster token in 2023 on the ethereum
            blockchain.
          </p>
        </MessageDescription>
      </TokenSectionContent>
    </TokenSectionWrapper>
  );
};
