import React, { FunctionComponent } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import {
  useClaimConditions,
  useClaimNFT,
  useProgram,
} from "@thirdweb-dev/react/solana";
import styled from "styled-components";
import { useWallet } from "@solana/wallet-adapter-react";

import {
  BREAKPOINTS,
  mediaQueries,
  MintMonsterButtonBaseStyles,
} from "../styles";
import { programAddress } from "../constants";

require("@solana/wallet-adapter-react-ui/styles.css");

const MintingSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const MintingContentSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 10px;
  text-align: center;

  ${mediaQueries.SM} {
    width: ${BREAKPOINTS.SM};
  }
`;

const GreenmonsterHeaderImage = styled.img`
  height: auto;
  width: 100%;
`;

const MainHeader = styled.h1`
  font-family: Rockwell, Courier Bold, Courier, Georgia, Times, Times New Roman,
    serif;
  font-size: 42px;
  margin-bottom: 0;
  text-shadow: 2px 2px 0px #eee;
`;

const NFTCost = styled.h3`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const StyledWalletMultiButtonLargeWrapper = styled.div`
  display: flex;
  justify-content: center;

  button {
    ${MintMonsterButtonBaseStyles}
    background-color: #81a64b;
    border: 1px solid #5a7334;
    font-size: 24px;
    font-family: inherit;
    font-weight: inherit;
    height: inherit;
    line-height: inherit;

    &:not([disabled]):hover {
      background-color: #718c41;
      border: 1px solid #5a7334;
    }
  }
`;

const MintMonsterButton = styled.button`
  ${MintMonsterButtonBaseStyles}
  background-color: #81a64b;
  border: 1px solid #5a7334;
  color: #fff;
  cursor: pointer;
  text-align: center;
  text-shadow: 1px 1px #5a7334;

  &:hover {
    background-color: #87c117;
  }
`;

const NFTCollectionCount = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
`;

export const MintingSection: FunctionComponent = () => {
  const wallet = useWallet();
  const { program } = useProgram(programAddress, "nft-drop");

  const { mutateAsync: claim, isLoading: claimIsLoading } =
    useClaimNFT(program);

  const { data: claimConditions, isLoading: claimConditionsIsLoading } =
    useClaimConditions(program);

  const remainingNFTSupply =
    claimConditions &&
    claimConditions?.totalAvailableSupply - claimConditions?.claimedSupply;

  const monsterPriceNumber = Number(claimConditions?.price.displayValue);
  const monsterPrice = monsterPriceNumber;
  // const monsterPrice = monsterPriceNumber.toFixed(2);

  return (
    <MintingSectionWrapper>
      <MintingContentSection>
        <GreenmonsterHeaderImage
          src="./monsters/green-monster.png"
          alt="Green Imagination Monster"
        />

        <MainHeader>The monsters are here</MainHeader>

        <NFTCost>{!claimConditionsIsLoading && monsterPrice} solana</NFTCost>

        {wallet.connected ? (
          <MintMonsterButton
            disabled={claimIsLoading}
            onClick={() => claim({ amount: 1 })}
          >
            Buy a Monster
          </MintMonsterButton>
        ) : (
          <StyledWalletMultiButtonLargeWrapper>
            <WalletMultiButton style={{}} />
          </StyledWalletMultiButtonLargeWrapper>
        )}

        {!claimConditionsIsLoading && wallet.connected && (
          <NFTCollectionCount>
            Only {remainingNFTSupply} monster remain.
          </NFTCollectionCount>
        )}
      </MintingContentSection>
    </MintingSectionWrapper>
  );
};
