import React, { FunctionComponent } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { useClaimNFT, useProgram } from "@thirdweb-dev/react/solana";
import styled from "styled-components";

import { BREAKPOINTS, mediaQueries } from "../styles";
import { programAddress } from "../constants";

require("@solana/wallet-adapter-react-ui/styles.css");

const HeaderWrapper = styled.div`
  background-color: #9d58a6;
  box-shadow: 0px 0px 20px #000;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
`;

const HeaderContent = styled.header`
  align-items: center;
  display: flex;
  height: 60px;
  position: relative;
  width: 100%;

  ${mediaQueries.SM} {
    width: ${BREAKPOINTS.SM};
  }
`;

const LogoImage = styled.svg`
  margin-left: 10px;
  width: 50px;
`;

const CompanyName = styled.h1`
  color: #fff;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  line-height: 14px;
  margin-left: 5px;
  text-shadow: 1px 1px #834c8c;
  width: 100px;
`;

const RightButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

const StyledWalletMultiButtonWrapper = styled.div`
  margin-right: 10px;

  button {
    background-color: transparent;
    border: 1px solid #77457f;
    font-weight: normal;
    height: 40px;
    padding: 0 10px;

    &:not([disabled]):hover {
      background-color: #718c41;
      border: 1px solid #5a7334;
    }
  }
`;

const MintMonsterButton = styled.button`
  display: none;

  ${mediaQueries.XS_LANDSCAPE} {
    background-color: #81a64b;
    border: 1px solid #77457f;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    height: 40px;
    margin-right: 10px;
    padding: 0 10px;
    text-align: center;
    text-shadow: 1px 1px #5a7334;

    &:hover {
      background-color: #87c117;
    }
  }
`;

export const Header: FunctionComponent = () => {
  const wallet = useWallet();
  const { program } = useProgram(programAddress, "nft-drop");

  const {
    mutateAsync: claim,
    isLoading: claimIsLoading,
    error: claimError,
  } = useClaimNFT(program);

  return (
    <HeaderWrapper>
      <HeaderContent>
        <LogoImage data-name="imgm-logo" viewBox="0 0 99 73.8">
          <path
            fill="#fff"
            d="M97.99,45.46c-.36-1.73-1.47-3.22-2.81-4.19-1.35-.97-2.94-1.42-4.42-1.17-4.23,.67-8.49,1.18-12.79,1.55-2.53,.03-4.69-.05-5.33-.34-1.5-.67-.61-2.53,1.3-2.89,6.16-1.15,9.81-2.51,11.62-3.93,5.15-3.34,8.56-9.13,8.57-15.73C94.14,8.41,85.75,0,75.4,0c-10.35,0-18.75,8.38-18.76,18.73,0,5.04,1.98,9.61,5.21,12.98,1.43,1.55,3.51,3.36,6.36,5.46,1.85,1.36,.78,3.16-1.83,4.37-1.12,.52-3.44,.84-6.02,1.04-1.95,.05-3.91,.08-5.88,.1,0,0,0,0,0,0-5,0-.25-2.77-.32-5.6,.03-1.62-2.8-5.77-3.23,1.64,.16,2.23-1.96,6.55-1.43,.41,.11-1.53-.27-3.09-1.91-3.04-1.22,.13-.72,2.57-.2,3.55,.85,1.46,.61,2.96-1.23,3.07-1.57,0-3.09-.02-4.58-.03-3.15-.19-6.2-.53-7.53-1.14-2.61-1.21-3.68-3.01-1.83-4.37,2.85-2.1,4.93-3.91,6.36-5.46,3.23-3.37,5.21-7.94,5.21-12.98C43.76,8.38,35.36,0,25.01,0,14.66,0,6.27,8.41,6.28,18.76c0,6.6,3.42,12.39,8.57,15.73,1.81,1.42,5.46,2.79,11.62,3.93,1.91,.36,2.79,2.22,1.3,2.89-.88,.39-4.62,.4-8.28,.26-3.59-.37-7.29-.85-11.24-1.48-1.48-.25-3.07,.2-4.42,1.17-1.35,.97-2.46,2.46-2.81,4.19-1.34,6.44-1.34,12.88,0,19.31,.36,1.73,1.47,3.22,2.81,4.19,1.35,.97,2.94,1.42,4.42,1.17,5.21-.83,10.38-1.41,15.68-1.82,.07,0,.15,0,.22,0,4.22,.07-.23,5.31,2.36,5.48,3,0,16.27-.04,19.93-.04,2.74-.18-1.92-4.67,3.43-4.81,5.01,.03,.28,4.56,2.93,4.83,2.29,0,13.73,.02,19.83-.02,2.33-.12-1.93-5.33,1.67-5.58,.01,0,.02,0,.04,0,5.21,.39,10.52,1,16.43,1.94,1.48,.25,3.07-.2,4.42-1.17,1.35-.97,2.46-2.46,2.81-4.19,1.34-6.44,1.34-12.88,0-19.31Z"
          />
        </LogoImage>

        <CompanyName>Imagination Monsters</CompanyName>

        <RightButtonsWrapper>
          {wallet.connected && (
            <MintMonsterButton
              disabled={claimIsLoading}
              onClick={() => claim({ amount: 1 })}
            >
              Buy a Monster
            </MintMonsterButton>
          )}

          <StyledWalletMultiButtonWrapper>
            <WalletMultiButton style={{}} />
          </StyledWalletMultiButtonWrapper>
        </RightButtonsWrapper>
      </HeaderContent>
    </HeaderWrapper>
  );
};
