import { css } from "styled-components";

export const MintMonsterButtonBaseStyles = css`
  border-radius: 5px;
  font-size: 24px;
  margin-bottom: 5px;
  padding: 20px 40px;
`;

export const walletButtonBaseStyles = css`
  background-color: #5a7334;
  border: 1px solid #3d4c23;
`;
