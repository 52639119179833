import React, { FunctionComponent } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { BREAKPOINTS } from "./breakpoints";

const GlobalStyles = createGlobalStyle`
  /* https://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
	font-family: Arial,Helvetica Neue,Helvetica,sans-serif; 
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  background-color: #ffffff;
}
a{
  text-decoration: none;
}
strong {
  font-weight: 800;
}
em{
  font-style: italic;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* My general site styles */
p {
  margin-bottom: 10px;
}

a {
  color: #4895d9;
  text-decoration: underline;
}
`;

export const UIProvider: FunctionComponent<any> = ({ children }) => (
  <ThemeProvider
    theme={{
      BREAKPOINTS,
    }}
  >
    <>
      <GlobalStyles />
      {children}
    </>
  </ThemeProvider>
);
