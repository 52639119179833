import React, { FunctionComponent } from "react";
import styled from "styled-components";

const BornSectionWrapper = styled.div`
  background-color: #81a64b;
  color: #fff;
  display: flex;
  justify-content: center;
`;

const BornSectionContent = styled.section`
  padding: 5px 0;
  text-align: center;
`;

const MessageTitle = styled.p`
  font-size: 18px;
  margin: 0;
  text-shadow: 1px 1px #5a7334;
`;

export const BornSection: FunctionComponent = () => {
  return (
    <BornSectionWrapper>
      <BornSectionContent>
        <MessageTitle>Born and Raised in the USA</MessageTitle>
      </BornSectionContent>
    </BornSectionWrapper>
  );
};
