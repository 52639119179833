import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { BREAKPOINTS, mediaQueries } from "../styles";

const RewardSectionWrapper = styled.div`
  background-color: #fff;
  justify-content: center;
  display: flex;
`;

const RewardSectionContent = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  text-align: center;

  ${mediaQueries.SM} {
    width: ${BREAKPOINTS.SM};
  }
`;

const ImaginationMonstersAddress = styled.div`
  font-size: 12px;
  font-weight: bold;
  padding: 10px 0 5px;
`;

const ImaginationMonstersAddressDescription = styled.div`
  font-size: 12px;
  font-style: italic;
`;

const ImaginationMonstersAddressTitle = styled.h3`
  font-family: Rockwell, Courier Bold, Courier, Georgia, Times, Times New Roman,
    serif;
  font-size: 24px;
  padding: 40px 0 0;
`;

const MessageTitle = styled.h1`
  font-family: Rockwell, Courier Bold, Courier, Georgia, Times, Times New Roman,
    serif;
  font-size: 40px;
  text-shadow: 2px 2px 0px #eee;
`;

const MessageDescription = styled.div``;

const MonsterImage = styled.img`
  width: 10%;
  height: auto;

  ${mediaQueries.SM} {
    width: 400px;
  }
`;

const RewardList = styled.ul``;

const RewardListItem = styled.div`
  ${mediaQueries.SM} {
    display: flex;
  }
`;

const RewardDescription = styled.p``;

const RewardTitle = styled.h3`
  font-size: 24px;
`;

const RewardSubTitle = styled.h3`
  margin-bottom: 10px;
`;

const Rewardwrapper = styled.div`
  ${mediaQueries.SM} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }
`;

const TwitterUrl = styled.a``;

export const RewardSection: FunctionComponent = () => {
  return (
    <RewardSectionWrapper>
      <RewardSectionContent>
        <MessageTitle>Monster Rewards</MessageTitle>
        <MessageDescription>
          Find one of these monsters and send it to the address below to receive
          the reward.
        </MessageDescription>

        <RewardList>
          <RewardListItem>
            <MonsterImage
              src="./monsters/air-monster.png"
              alt="Air monster giveaway image"
            />

            <Rewardwrapper>
              <RewardTitle>Air Monster</RewardTitle>
              <RewardSubTitle>Reward: 10 sol</RewardSubTitle>

              <RewardDescription>
                Light-hearted and full of laughter, the Air Monster takes steps
                to help improve our atmosphere. Wether it's by teaching others
                or advocating for better practices, our air quality is always
                top of mind for this monster.{" "}
                <TwitterUrl
                  href="https://twitter.com/chriscampb3ll"
                  target="_blank"
                >
                  Do something great for your neighborhood and post about it
                </TwitterUrl>
                . You never know what may come about.
              </RewardDescription>
            </Rewardwrapper>
          </RewardListItem>

          <RewardListItem>
            <MonsterImage
              src="./monsters/earth-monster.png"
              alt="Earth monster giveaway image"
            />

            <Rewardwrapper>
              <RewardTitle>Earth Monster</RewardTitle>
              <RewardSubTitle>Reward: 10 sol</RewardSubTitle>
              <RewardDescription>
                Created from the ground up, the Earth Monster cares deeply for
                this planet and the earth it came from. It always goes out of
                it's way to take care and improve it's earthly surroundings. If
                you feel inspired,{" "}
                <TwitterUrl
                  href="https://twitter.com/chriscampb3ll"
                  target="_blank"
                >
                  do something that improves your own earthly surroundings and
                  share it on twitter
                </TwitterUrl>{" "}
                Grow your positive influence.
              </RewardDescription>
            </Rewardwrapper>
          </RewardListItem>

          <RewardListItem>
            <MonsterImage
              src="./monsters/water-monster.png"
              alt="Water monster giveaway image"
            />

            <Rewardwrapper>
              <RewardTitle>Water Monster</RewardTitle>
              <RewardSubTitle>Reward: 10 sol</RewardSubTitle>
              <RewardDescription>
                The Water Monster usually goes with the flow, but has an
                overflowing love for keeping our water clean. Teaching is a true
                passion of this monster, and is always happy to share new things
                with others.{" "}
                <TwitterUrl
                  href="https://twitter.com/chriscampb3ll"
                  target="_blank"
                >
                  Find an opportunity to improve something for the better. Do
                  it, and share it with us
                </TwitterUrl>
                . Your actions will help inspire others to do the same.
              </RewardDescription>
            </Rewardwrapper>
          </RewardListItem>

          <RewardListItem>
            <MonsterImage
              src="./monsters/fire-monster.png"
              alt="Fire monster giveaway image"
            />

            <Rewardwrapper>
              <RewardTitle>Fire Monster</RewardTitle>
              <RewardSubTitle>Reward: 10 sol</RewardSubTitle>
              <RewardDescription>
                Some say that the Fire Monster has been here since the dawn of
                time. Erupted from the earths core, this monster has been both a
                blessing and a curse to all who have encountered it.{" "}
                <TwitterUrl
                  href="https://twitter.com/chriscampb3ll"
                  target="_blank"
                >
                  Go out of your way to do something nice for someone and let
                  people know on twitter
                </TwitterUrl>
                . Good things are great to spread to others.
              </RewardDescription>
            </Rewardwrapper>
          </RewardListItem>

          <RewardListItem>
            <MonsterImage
              src="./monsters/golden-monster.png"
              alt="Golden monster giveaway image"
            />

            <Rewardwrapper>
              <RewardTitle>Golden Monsters</RewardTitle>
              <RewardSubTitle>Reward: 5 sol each</RewardSubTitle>
              <RewardDescription>
                There are 20 golden monsters lurking about. Some alive and some
                ghosts. Rumor has it that these monsters were the very last to
                be created.
              </RewardDescription>
            </Rewardwrapper>
          </RewardListItem>
        </RewardList>

        <ImaginationMonstersAddressTitle>
          Send these monsters to this address
        </ImaginationMonstersAddressTitle>

        <ImaginationMonstersAddress>
          IMGM address: HwzTytfPdbokx7fTKvRBzx9mAysQuVx2N4g46XHDwhJo
        </ImaginationMonstersAddress>

        <ImaginationMonstersAddressDescription>
          Please note: After all monsters have been sold I will issue out
          rewards. The best thing you can do is to help promote my project.
        </ImaginationMonstersAddressDescription>
      </RewardSectionContent>
    </RewardSectionWrapper>
  );
};
