import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { BREAKPOINTS, mediaQueries } from "../styles";

const DiscordSection = styled.div``;

const SocialSectionWrapper = styled.div`
  background-color: #9d58a6;
  color: #fff;
  display: flex;
  justify-content: center;
`;

const SocialSectionContent = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  text-align: center;

  ${mediaQueries.SM} {
    width: ${BREAKPOINTS.SM};
  }
`;

const MessageTitle = styled.h1`
  font-family: Rockwell, Courier Bold, Courier, Georgia, Times, Times New Roman,
    serif;
  font-size: 40px;
  text-shadow: 1px 1px #834c8c;

  ${mediaQueries.SM} {
    text-shadow: 3px 3px #834c8c;
  }
`;

export const SocialSection: FunctionComponent = () => {
  return (
    <SocialSectionWrapper>
      <SocialSectionContent>
        <DiscordSection>
          <MessageTitle>Monster Discord Server Coming Soon!</MessageTitle>
        </DiscordSection>
      </SocialSectionContent>
    </SocialSectionWrapper>
  );
};
