import React, { FunctionComponent } from "react";
import styled from "styled-components";

import {
  BornSection,
  Header,
  MintingSection,
  UtilitySection,
} from "../components";
require("@solana/wallet-adapter-react-ui/styles.css");

const MainContentWrapper = styled.div``;

export const App: FunctionComponent = () => {
  return (
    <>
      <Header />

      <MainContentWrapper>
        <BornSection />

        <MintingSection />

        <UtilitySection />
      </MainContentWrapper>
    </>
  );
};
